import { DateField, FilterDropdown, getDefaultSortOrder, List, ShowButton } from "@refinedev/antd";
import { Button, Input, Select, Table } from "antd";
import { ShortId } from "./ShortId";
import React, { useMemo } from "react";
import { EventNames, IEventModel } from "../models/EventsModel";
import { CrudFilter } from "@refinedev/core";
import { UserOutlined, TeamOutlined, EyeOutlined } from "@ant-design/icons";
import TableTitleWithCount from "./TableTitleWithCount";
import useTableWithSearch from "../hooks/useTableWithSearch";
import useMetaViewer from "../hooks/useMetaViewer";

type EventListProps = {
  teamId?: string
  userId?: string
  collectionId?: string
  documentId?: string
  actorId?: string
}

const EventList: React.FC<EventListProps> = ({
  teamId,
  userId,
  collectionId,
  documentId,
  actorId,
}) => {
  const { showMeta, metaComponent } = useMetaViewer();
  const filter: CrudFilter[] = useMemo(() => {
    const filter: CrudFilter[] = [];
    if (teamId) {
      filter.push({
        field: 'teamId',
        operator: 'eq',
        value: teamId
      })
    }
    if (userId) {
      filter.push({
        field: 'userId',
        operator: 'eq',
        value: userId
      })
    }
    if (collectionId) {
      filter.push({
        field: 'collectionId',
        operator: 'eq',
        value: collectionId
      })
    }
    if (documentId) {
      filter.push({
        field: 'documentId',
        operator: 'eq',
        value: documentId
      })
    }
    if (actorId) {
      filter.push({
        field: 'actorId',
        operator: 'eq',
        value: actorId
      })
    }
    return filter;
  }, [actorId, collectionId, documentId, teamId, userId]);

  const {
    tableData: { tableProps, sorter, tableQueryResult },
    getColumn,
    clear,
  } = useTableWithSearch<IEventModel>({
    resource: 'events',
    initialFilter: filter,
    filterFields: ["name", "team.subdomain", "ip"],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  return (
    <List
      title={<TableTitleWithCount title="Events" tableQueryResult={tableQueryResult} />}
      canCreate={false}
      headerProps={{ breadcrumb: undefined }}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table<IEventModel>
        {...tableProps}
        size="small"
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={ShortId}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select values"
              >
                {EventNames.map(value => (
                  <Select.Option key={value} value={value}>{value}</Select.Option>
                ))}
              </Select>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={['team','subdomain']}
          key="teamId"
          title="Team"
          {...getColumn("team.subdomain")}
          render={(value, record: IEventModel) => (
            <ShowButton
              type="link"
              icon={<TeamOutlined />}
              recordItemId={record?.teamId}
              resource="teams"
            >
              {value}
            </ShowButton>
          )}
          filterDropdown={(props) => (
            <Input
              name="filter-dropdown-input"
              size="middle"
            />
          )}
        />
        <Table.Column
          dataIndex={['actor','email']}
          key="actorId"
          title="Actor"
          render={(value, record: IEventModel) => value ? (
            <ShowButton
              type="link"
              icon={<UserOutlined />}
              recordItemId={record?.userId}
              resource="users"
            >
              {value}
            </ShowButton>
          ) : ''}
        />
        <Table.Column
          dataIndex={['user','email']}
          key="userId"
          title="User"
          render={(value, record: IEventModel) => value ? (
            <ShowButton
              type="link"
              icon={<UserOutlined />}
              recordItemId={record?.userId}
              resource="users"
            >
              {value}
            </ShowButton>
          ) : ''}
        />
        <Table.Column
          dataIndex="collectionId"
          key="collectionId"
          title="Collection"
          render={ShortId}
        />
        <Table.Column
          dataIndex="documentId"
          key="documentId"
          title="Document"
          render={ShortId}
        />
        <Table.Column
          dataIndex="modelId"
          key="modelId"
          title="Model"
          render={ShortId}
        />
        <Table.Column
          dataIndex="ip"
          key="ip"
          title="IP"
          {...getColumn("ip")}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex={["data"]}
          key="data"
          title="Data"
          render={(value) => value && <Button
            icon={<EyeOutlined />}
            title="Show DATA"
            onClick={() => showMeta(value)}
          />}
        />
      </Table>
      {metaComponent}
    </List>
  );
}

export default EventList;
