
const AttachmentsModel = {
  id: {
    type: 'string',
  },
  teamId: {
    type: 'string',
  },
  userId: {
    type: 'string',
  },
  documentId: {
    type: 'string',
  },
  size: {
    type: 'number',
  },
  createdAt: {
    type: 'Date',
  },
  updatedAt: {
    type: 'Date',
  },
  key: {
    type: 'string',
  },
  url: {
    type: 'string',
  },
  contentType: {
    type: 'string',
  },
  acl: {
    type: 'string',
  },

}
export default AttachmentsModel