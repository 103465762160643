
const DocumentsModel = {
  createdAt: {
    type: 'Date',
  },
  updatedAt: {
    type: 'Date',
  },
  userId: {
    type: 'string',
  },
  collectionId: {
    type: 'string',
  },
  teamId: {
    type: 'string',
  },
  parentDocumentId: {
    type: 'string',
  },
  lastModifiedById: {
    type: 'string',
  },
  revisionCount: {
    type: 'number',
  },
  deletedAt: {
    type: 'Date',
  },
  createdById: {
    type: 'string',
  },
  collaboratorIds: {
    type: 'string',
  },
  publishedAt: {
    type: 'Date',
  },
  pinnedById: {
    type: 'string',
  },
  archivedAt: {
    type: 'Date',
  },
  isWelcome: {
    type: 'boolean',
  },
  version: {
    type: 'number',
  },
  template: {
    type: 'boolean',
  },
  templateId: {
    type: 'string',
  },
  fullWidth: {
    type: 'boolean',
  },
  properties: {
    type: 'object',
  },
  tableOrder: {
    type: 'number',
  },
  id: {
    type: 'string',
  },
  urlId: {
    type: 'string',
  },
  title: {
    type: 'string',
  },
  text: {
    type: 'string',
  },
  emoji: {
    type: 'string',
  },
  editorVersion: {
    type: 'string',
  },
  previousTitles: {
    type: 'string',
  },
  type: {
    type: 'string',
  },

}
export default DocumentsModel