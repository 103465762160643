import { useTable, useTableProps } from "@refinedev/antd";
import { Input } from "antd";
import { BaseRecord, CrudFilter, HttpError } from "@refinedev/core";
import React, { useCallback, useMemo, useState } from "react";
import useDebouncedState from "./useDebouncedState";
import { CrudFilters } from "@refinedev/core/src/interfaces";

interface UseTableWithSearchProps<TData, TError, TSearchVariables>
  extends Omit<useTableProps<TData, TError, TSearchVariables>, "filters"> {
  filterFields?: string[];
  permanentFilters?: CrudFilter[] | CrudFilters;
  limit?: number;
}

function useTableWithSearch<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TSearchVariables = unknown
>({
  filterFields,
  limit = 50,
  ...tableProps
}: UseTableWithSearchProps<TData, TError, TSearchVariables>) {
  const [searchText, setSearchText] = useState<Record<string, string>>({});
  const debouncedSearchText = useDebouncedState<Record<string, string>>(
    searchText,
    500
  );

  const filters = useMemo<CrudFilter[]>(() => {
    return !filterFields
      ? []
      : filterFields
          .map((fieldName) => {
            const value = debouncedSearchText[fieldName] ?? null;

            return {
              field: fieldName,
              operator: "contains",
              value,
            } as CrudFilter;
          })
          .filter(({ value }) => !!value);
  }, [filterFields, debouncedSearchText]);

  const tableData = useTable<TData, TError, TSearchVariables>({
    ...tableProps,
    filters: {
      permanent: tableProps.permanentFilters
        ? [...tableProps.permanentFilters, ...filters]
        : filters,
    },
    initialPageSize: limit,
    queryOptions: {
      retry: false,
      refetchOnMount: true,
    },
  });

  const onInputChange = useCallback(
    (columnName: string, value: string) => {
      setSearchText({ ...searchText, [columnName]: value });
    },
    [searchText]
  );

  const clear = useCallback(() => {
    setSearchText({});
  }, []);

  const getColumn = useCallback(
    (columnName: string) => {
      return {
        ...tableProps,
        filterDropdown: () => (
          <FilterInput
            columnName={columnName}
            searchText={searchText[columnName]}
            onInputChange={onInputChange}
          />
        ),
      };
    },
    [onInputChange, searchText, tableProps]
  );

  if (tableData.tableProps.pagination) {
    tableData.tableProps.pagination["showSizeChanger"] = true;
    tableData.tableProps.pagination["pageSizeOptions"] = [50, 100, 150, 200];
  }

  return {
    tableData,
    getColumn,
    clear,
  };
}

type FilterInputProps = {
  columnName: string;
  searchText: string;
  onInputChange: (columnName: string, value: string) => void;
};

const FilterInput: React.FC<FilterInputProps> = ({
  columnName,
  searchText,
  onInputChange,
}) => {
  return (
    <Input
      autoFocus={true}
      value={searchText}
      size="middle"
      placeholder={`Search in ${columnName}`}
      onChange={(event) => onInputChange(columnName, event.target.value)}
    />
  );
};

export default useTableWithSearch;
