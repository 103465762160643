import { Refine } from "@refinedev/core";
import {
  ErrorComponent,
  Header,
  notificationProvider,
  ThemedLayoutV2,
} from "@refinedev/antd";
import routerProvider, { NavigateToResource } from "@refinedev/react-router-v6";
import "@refinedev/antd/dist/reset.css";
import "./style.css";
import nestjsxCrudDataProvider from "@refinedev/nestjsx-crud";
import ac from "./ac";
import {
  DefaultCrudCreate,
  DefaultCrudEdit,
  DefaultCrudList,
  DefaultCrudShow,
  IDefaultModel,
} from "./components/DefaultCrud";
import Title from "./components/layout/Title";
import AttachmentsModel from "./models/AttachmentsModel";
import DocumentsModel from "./models/DocumentsModel";
import { API_URL } from "./constants";
import BillingModule from "./pages/Billing";
import YonoteModule from "./pages/Yonote";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { ConfigProvider, theme } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import Spin from "antd/lib/spin";
import "./init-dayjs";

const GroupsModel: IDefaultModel = {
  id: {
    type: "string",
  },
  name: {
    type: "string",
  },
};

function App() {
  const dataProvider = nestjsxCrudDataProvider(API_URL, axios);

  const accessControl = ac;

  YonoteModule.accessControl(ac);
  BillingModule.accessControl(ac);

  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return (
      <div className="big-loader">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
      </div>
    );
  }

  if (!keycloak.authenticated) {
    keycloak.login();
  }

  const {
    tokenParsed: { groups },
  } = keycloak;

  return (
    <BrowserRouter>
      <ConfigProvider theme={{ algorithm: theme.compactAlgorithm }}>
        <Refine
          routerProvider={routerProvider}
          notificationProvider={notificationProvider}
          dataProvider={dataProvider}
          options={{
            disableTelemetry: true,
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
          }}
          accessControlProvider={{
            can: async ({ resource, action }) => {
              let can: boolean = false;
              groups.forEach((role: string) => {
                role = role.toUpperCase();

                switch (action) {
                  case "list":
                  case "show":
                    can = accessControl.can(role).read(resource).granted;
                    break;
                  case "create":
                    can = accessControl.can(role).create(resource).granted;
                    break;
                  case "edit":
                    can = accessControl.can(role).update(resource).granted;
                    break;
                  case "delete":
                    can = accessControl.can(role).delete(resource).granted;
                    break;
                }
              });
              return Promise.resolve({ can });
            },
          }}
          resources={[
            ...BillingModule.resources,
            ...YonoteModule.resources,
            {
              name: "groups",
              list: (props) => (
                <DefaultCrudList
                  model={GroupsModel}
                  filterFields={["id", "name"]}
                  {...props}
                />
              ),
              create: (props) => (
                <DefaultCrudCreate model={GroupsModel} {...props} />
              ),
              edit: (props) => (
                <DefaultCrudEdit model={GroupsModel} {...props} />
              ),
              show: (props) => (
                <DefaultCrudShow model={GroupsModel} {...props} />
              ),
            },
            {
              name: "attachments",
              list: (props) => (
                <DefaultCrudList
                  model={AttachmentsModel}
                  filterFields={["id", "key", "url", "contentType", "acl"]}
                  {...props}
                />
              ),
              create: (props) => (
                <DefaultCrudCreate model={AttachmentsModel} {...props} />
              ),
              edit: (props) => (
                <DefaultCrudEdit model={AttachmentsModel} {...props} />
              ),
              show: (props) => (
                <DefaultCrudShow model={AttachmentsModel} {...props} />
              ),
            },
            {
              name: "documents",
              list: (props) => (
                <DefaultCrudList
                  model={DocumentsModel}
                  filterFields={[
                    "id",
                    "title",
                    "text",
                    "type",
                    "emoji",
                    "editorVersion",
                  ]}
                  {...props}
                />
              ),
              create: (props) => (
                <DefaultCrudCreate model={DocumentsModel} {...props} />
              ),
              edit: (props) => (
                <DefaultCrudEdit model={DocumentsModel} {...props} />
              ),
              show: (props) => (
                <DefaultCrudShow model={DocumentsModel} {...props} />
              ),
            },
          ]}
        >
          <Routes>
            <Route
              element={
                <ThemedLayoutV2 Header={() => <Header />} Title={Title}>
                  <Outlet />
                </ThemedLayoutV2>
              }
            >
              <Route
                index
                element={
                  <NavigateToResource
                    resource={BillingModule.resources[0].name}
                  />
                }
              />

              {BillingModule.routes}
              {YonoteModule.routes}

              <Route path="*" element={<ErrorComponent />} />
            </Route>
          </Routes>
        </Refine>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
