import { IResourceComponentsProps } from "@refinedev/core";

import {
  List,
  TextField,
  getDefaultSortOrder,
  EditButton,
  DeleteButton,
  TagField,
  DateField,
} from "@refinedev/antd";

import { Table, Space, Button } from "antd";

import React, { useMemo } from "react";
import { IDefaultModel, IDefaultEntity } from "./index";
import useTableWithSearch from "../../hooks/useTableWithSearch";
import { ShortId } from "../ShortId";

const ObjectField: React.FC<{ value: any }> = ({ value }) => (
  <span>{JSON.stringify(value)}</span>
);

interface IDefaultCrudListProps extends IResourceComponentsProps {
  model: IDefaultModel;
  filterFields?: string[];
}

export const DefaultCrudList: React.FC<IDefaultCrudListProps> = ({
  model,
  filterFields = [],
}) => {
  const {
    tableData: { tableProps, sorter },
    getColumn,
    clear,
  } = useTableWithSearch<IDefaultEntity>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    filterFields,
  });

  const columns = useMemo(() => {
    return Object.keys(model).map((columnName) => {
      const RenderComponent =
        model[columnName].type === "text"
          ? TextField
          : model[columnName].type === "boolean"
          ? TagField
          : model[columnName].type === "Date"
          ? DateField
          : model[columnName].type === "object"
          ? ObjectField
          : TextField;

      const searchProps = filterFields?.includes(columnName)
        ? getColumn(columnName)
        : {};

      const onRender = (value: string) => {
        if (columnName.toLowerCase().substr(-2,2) === "id") {
          return ShortId(value)
        }
        return <RenderComponent value={value} />
      }

      return (
        <Table.Column
          dataIndex={columnName}
          key={columnName}
          title={columnName}
          {...searchProps}
          render={onRender}
          defaultSortOrder={getDefaultSortOrder(columnName, sorter)}
          sorter
        />
      );
    });
  }, [filterFields, getColumn, model, sorter]);

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table {...tableProps} rowKey="id" size="small">
        {columns}
        <Table.Column<IDefaultEntity>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
